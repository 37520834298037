import { For, mergeProps, splitProps } from 'solid-js';
import { colors } from '@troon/tailwind-preset/colors';
import { Title } from '@solidjs/meta';
import { twMerge } from '@troon/tailwind-preset/merge';
import { useFocusable, useToast } from '@troon/ui';
import type { JSX } from 'solid-js';

export default function Colors() {
	const addToast = useToast();

	return (
		<>
			<Title>Colors / Styleguide</Title>
			<h1 class="mb-4 text-4xl font-semibold">Colors</h1>
			<ul class="flex flex-col gap-y-12">
				<For each={Object.entries(colors)}>
					{([key, values]) =>
						key.startsWith('current') ? null : (
							<li>
								<h2 class="mb-4 text-2xl font-semibold">{key}</h2>
								<ul class="grid grid-cols-12 gap-4">
									<For each={typeof values === 'string' ? [['DEFAULT', values]] : Object.entries(values)}>
										{([ramp, value]) => (
											<li class="col-span-12 md:col-span-6 lg:col-span-4 2xl:col-span-3">
												<ColorCard
													ramp={ramp!}
													value={value!}
													onClick={() => {
														window.navigator.clipboard.writeText(ramp === 'DEFAULT' ? key : `${key}-${ramp}`);
														addToast(`Copied ${ramp === 'DEFAULT' ? key : `${key}-${ramp}`} to clipboard!`, {
															timeout: 2000,
															variant: 'positive',
														});
													}}
												/>
											</li>
										)}
									</For>
								</ul>
							</li>
						)
					}
				</For>
			</ul>
		</>
	);
}

function ColorCard(inputProps: JSX.HTMLAttributes<HTMLButtonElement> & { ramp: string; value: string }) {
	const focusProps = useFocusable();
	const [, props] = splitProps(mergeProps(inputProps, focusProps), ['ramp', 'value']);

	return (
		<button
			{...props}
			class={twMerge(
				'grid w-full grid-cols-3 overflow-hidden rounded-md border border-neutral-500 outline-none transition-all duration-200 hover:border-brand-400 hover:bg-brand-100/10 focus-visible:ring-4 focus-visible:ring-brand-100',
				props.class,
			)}
		>
			<span class="col-span-1 block h-full" style={{ 'background-color': inputProps.value }} />
			<div class="col-span-2 flex flex-col items-start p-4">
				<b>{inputProps.ramp}</b>
				<span class="font-mono text-neutral-700">{inputProps.value}</span>
			</div>
		</button>
	);
}
